<template>
    <div>
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">产品和技术是我们发展的根基</p>
                     <p class="note">以水务行业信息化管理系统软硬件产品为基础，以快速为客户提供个性化的解决方案为主要经营模式实现企业价值与客户价值共同成长。</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../assets/banner/banner2.png'>
    </section>
    <section class="am-path">
        <div class="am-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品中心</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container">
            <div class="nyright">
                <div class="nyplist">
                    <ul class="am-avg-md-3">
                        <!-- <li>
                            <a href='/views/product-2'>
                            <i> <img src='~images/500 (1).jpg'></i>
                            <div class="txt">
                                <p class="tit" >综合监控服务器（高配型）</p>
                                <p class="note">RZY-8800</p>
                            </div>
                            </a>
                        </li> -->
                        <li v-for="(item1,index) in dataList" :key='index'>
                            <a @click="func1(item1)">
                            <i> <img :src="/zhcwaterweb/+item1.furl"></i>
                            <div class="txt">
                                <p class="tit">{{item1.product_name}}</p>
                                <p class="note">{{item1.product_model}}</p>
                            </div>
                            </a>
                        </li>
                    </ul>
                <div class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="9"
                layout=" prev, pager, next"
                :total="this.total">
                </el-pagination>
            </div>
                </div>
            </div>
            <div class="nyleft">
                <el-col>
                <p class="tit">产品中心</p>
                 <el-menu
                 default-active="2"
                class="el-menu-vertical-demo"
                >
            <template v-for="item in data">
                <template v-if="item.list == null">
                <el-menu-item  :index="item.resultCode" :key="item.resultCode" style="color:#000" @click="router(item)">
                {{item.resultMsg}}
                </el-menu-item>
                </template>
                <template v-else>
                <el-submenu :key="item.resultCode"  :index="item.resultCode"  style="color:#000">
                <template slot="title">
                            {{item.resultMsg}}
                </template>

                <template v-for="subItem in item.list"   >
                <el-menu-item :index="subItem.resultCode" :key="subItem.resultCode" style="color:#000" @click="router(subItem)" >
                {{subItem.resultMsg}}
                </el-menu-item>  
                </template>
              </el-submenu>
            </template>
          </template>

                <!-- <el-submenu index ='item.index' v-for='(item,index) in data' :key="index">
                    <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{item.resultMsg}}</span>
                    </template>

                </el-submenu> -->
                </el-menu>
            </el-col> 
            </div>

        </div>
    </section>
    <!-- <section class="homed">
         <p>有疑问或想了解更多，请咨询：{{this.$parent.data.company_tel}}</p>
    </section> -->
    </div>
</template>
<script>
import {getList}from 'api/product'
import {getTreeList}from 'api/proClass'
export default {
    data() {
        return {
            dataList:[],
            data:[],
            total:1,
    searchInfo: {
        product_class:'',
        page_no: 1,
        page_size: 9,
      },
    searchInfo1: {
        page_no: 1,
        page_size: 9,
      },
        }
    },
    methods: {
        getList() {
        this.isShowloading = true;
        getList(this.searchInfo).then((res) => {
        this.dataList = res.data.list;
        this.total = res.data.entity.all_count
      });},        
      getTreeList() {
        this.isShowloading = true;
        getTreeList(this.searchInfo1).then((res) => {
        this.data = res.data.list;
      });},
        router(item1) {
            this.searchInfo.product_class = item1.resultMsg
            this.searchInfo.page_no = 1
            this.searchInfo.page_size = 9
            this.getList()
    },
      func1(item1) {
            let pathInfo = this.$router.resolve({
                path: '/views/product-2',
                query: {
                    id: item1.fuid 
                }
            })
            window.open(pathInfo.href,'_blank')
        // this.$router.push({name: 'product-2',params:{ id:item1.fuid}});
      },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.searchInfo.page_no = val
            this.getList()
         },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.searchInfo.page_no = val
            this.getList()

        },
    },
    mounted() {
    this.getList()
    this.getTreeList()
}
    
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}
}
.nybox {
    padding: 30px 0;
    background-color: #f5f5f5;
    .nyleft {
        width: 254px;
        height: 100%;
        .tit{
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }
        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }
    .nyright {
        width: 77%;
        float: right;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 30%;
            a{
                display: block;
                background: #fff;
                text-align: center;
                border: 1px solid #fff;
                overflow: hidden;
                i {
                    overflow: hidden;
                }
                img {
                    transition: all 0.7s;
                    height: 175px;
                }
                img:hover {
                    transform: scale(1.2) ;
                }
                .txt{
                    padding:20px;
                .tit {
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 4px;
                        color: #333;
                }
                .note {
                        font-size: 12px;
                        margin-bottom: 6px;
                        color: #999;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                }}
            }
            a:hover {
                border: 1px solid #0e90d2
            }
        }
        }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>
<style >
.el-pagination {
    margin: 20px 0;
}
</style>